import React, { FC, useRef } from "react"
import FsLightbox from "fslightbox-react"

export interface LightboxOnCloseProps {
  newSlideIndex: number
  changed: "forward" | "backward" | "same"
}

export interface LightboxProps {
  slide: number
  items: { publicURL: string; name: string }[]
  onClose: ({ newSlideIndex, changed }: LightboxOnCloseProps) => void
}

export const Lightbox: FC<LightboxProps> = ({ slide, items, onClose }) => {
  const lightboxRef = useRef<any>(null)
  const galleryUrls: string[] = items.map((edge: any) => edge.publicURL)

  // FSLightbox can't handle thousands of sources, so we only load 200 before and after the selected image
  const slideOffset = 200
  const minIndex = Math.max(0, slide - slideOffset)
  const maxIndex = Math.min(galleryUrls.length, slide + slideOffset)
  const sources = galleryUrls.filter((_, i) => i >= minIndex && i <= maxIndex)
  const currentSlide = slide - minIndex
  const captions = items
    .filter((_, i) => i >= minIndex && i <= maxIndex)
    .map(edge => edge.name.replace(/\_/g, " "))

  const handleClose = () => {
    const _currentSlide = currentSlide + 1
    const current = lightboxRef?.current?.stageIndexes.current
    const diff = current - _currentSlide
    const newSlideIndex = _currentSlide + diff
    let changed: "forward" | "backward" | "same" = "same"
    if (diff < -1) {
      changed = "backward"
    }
    if (diff > -1) {
      changed = "forward"
    }
    onClose({ newSlideIndex, changed })
  }

  return (
    <FsLightbox
      ref={lightboxRef}
      toggler={true}
      openOnMount={true}
      onClose={handleClose}
      sourceIndex={currentSlide}
      sources={sources}
      // @ts-ignore
      key={galleryUrls}
      // @ts-ignore
      captions={captions}
    />
  )
}
