import React from "react"
import Img from "gatsby-image"
import {
  Paper,
  Tooltip,
  withStyles,
  Theme,
  makeStyles,
} from "@material-ui/core"

export interface Props {
  image: any
  title: string
  onClick: () => void
}

const GalleryTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    arrow: {
      color: theme.palette.common.white,
    },
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    textAlign: "center",
  },
}))(Tooltip)

const useStyles = makeStyles((theme: Theme) => ({
  galleryItem: {
    textAlign: "center",
    height: "100%",
  },
  galleryItemPaper: {
    overflow: "hidden",
    cursor: "pointer",
    height: "100%",
  },
  galleryItemImage: {
    objectFit: "cover",
    height: "100%",
    width: "100%",
  },
}))

const GalleryItem = ({ image, title, onClick }: Props) => {
  const classes = useStyles()
  return (
    <GalleryTooltip title={title} placement="top" arrow>
      <div className={classes.galleryItem}>
        <Paper
          elevation={3}
          onClick={onClick}
          className={classes.galleryItemPaper}
        >
          <Img
            title={title}
            fluid={image}
            className={classes.galleryItemImage}
          />
        </Paper>
      </div>
    </GalleryTooltip>
  )
}

export default GalleryItem
