import React, { useState } from "react"
import { Button, Menu, MenuItem } from "@material-ui/core"
import { ArrowDropDownRounded } from "@material-ui/icons"

export interface SortbyOption {
  label: string
  sort: (a: any, b: any) => number
}

export interface SortbyMenuData {
  options: SortbyOption[]
  selectedOption: SortbyOption
  onClick: (option: SortbyOption) => void
}

export default function SortbyMenu({
  options,
  selectedOption,
  onClick,
}: SortbyMenuData) {
  const [anchor, setAnchor] = useState(null)

  const onMenuItemClick = (option: SortbyOption) => {
    setAnchor(null)
    onClick(option)
  }

  return (
    <>
      <Button
        aria-controls="sortby-menu"
        aria-haspopup="true"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchor(event.currentTarget as any)
        }}
      >
        Sort by: {selectedOption.label}
        <ArrowDropDownRounded />
      </Button>
      <Menu
        id="sortby-menu"
        anchorEl={anchor}
        disableScrollLock={true}
        keepMounted
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.label}
            selected={option.label === selectedOption.label}
            onClick={event => onMenuItemClick(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
