import React, { useEffect, useRef, useState } from "react"
import {
  Typography,
  Grid,
  Box,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { AutoSizer, List } from "react-virtualized"

import GalleryItem from "../components/gallery-item"
import SortbyMenu, { SortbyOption } from "../components/sortby-menu"
import { graphql, useStaticQuery } from "gatsby"
import { Lightbox, LightboxOnCloseProps } from "./lightbox"

const sortOptions: SortbyOption[] = [
  {
    label: "Name",
    sort: (a: any, b: any) => {
      return a.name.localeCompare(b.name, undefined, {
        numeric: true,
        sensitivity: "base",
      })
    },
  },
  {
    label: "Date",
    sort: (a: any, b: any) => {
      const _a = a.mtimeMs
      const _b = b.mtimeMs
      return _a - _b
    },
  },
]

export interface GalleryProps {
  title: string
  subtitle: string
  body?: React.ReactNode
  mediaItems: any[]
}

export default function Gallery({
  mediaItems,
  title,
  subtitle,
  body,
}: GalleryProps) {
  const listRef = useRef<any>(null)
  const { videoPlaceholder } = useStaticQuery(graphql`
    query {
      videoPlaceholder: file(relativePath: { eq: "video_placeholder.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, srcSetBreakpoints: [5000]) {
            aspectRatio
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const [lightboxSlide, setLightboxSlide] = useState(0)
  const [showLightbox, setShowLightbox] = useState(false)
  const [state, setState] = useState({
    sortByAnchor: null,
    sortBy: sortOptions[0],
    galleryItems: [] as any[],
  })
  const [rowCounts, setRowCounts] = useState<
    { index: number; itemWidths: number[] }[]
  >([])
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))

  useEffect(() => {
    const defaultSort = sortOptions[0]
    setState({
      sortByAnchor: null,
      sortBy: defaultSort,
      galleryItems: mediaItems.sort((a: any, b: any) => defaultSort.sort(a, b)),
    })
  }, [mediaItems])

  useEffect(() => {
    const items = state.galleryItems
    const sum = (arr: number[]) =>
      arr.reduce((prev, curr) => {
        return (prev += curr)
      }, 0)

    const rowCounts = []
    let rowItems: number[] = []
    for (let i = 0; i < items.length; i++) {
      if (window.innerWidth < 600) {
        rowCounts.push({ index: i, itemWidths: [1] })
      } else {
        const node = items[i]
        const image = isVideo(node)
          ? videoPlaceholder?.childImageSharp?.fluid
          : node?.thumbs?.fluid
        const { aspectRatio } = image
        const columnWidth = aspectRatio < 1 ? 1 : 2

        // If the image still fits in the 12 columns
        const total = sum([...rowItems, columnWidth])
        if (total <= 12) {
          rowItems.push(columnWidth)
        } else {
          rowCounts.push({ index: i, itemWidths: rowItems })
          rowItems = [columnWidth]
        }

        if (items.length - 1 === i) {
          rowCounts.push({ index: i + 1, itemWidths: rowItems })
        }
      }
    }
    setRowCounts(rowCounts)
  }, [state])

  const handleCloseLightbox = ({
    newSlideIndex,
    changed,
  }: LightboxOnCloseProps) => {
    let row = 0

    if (changed === "same") {
      setShowLightbox(false)
      return
    }

    if (isMobile) {
      row = newSlideIndex
    } else {
      let currentItems = 0
      while (newSlideIndex > currentItems) {
        currentItems += rowCounts[row].itemWidths.length
        row++
      }
    }

    const gotoRow = row + (changed === "forward" ? 2 : -1)
    listRef?.current?.scrollToRow(gotoRow)
    setShowLightbox(false)
  }

  const getGalleryItemsByCategory = ({ sortBy }: { sortBy?: any }) => {
    const _sortBy = sortBy || state.sortBy
    return mediaItems.sort((a: any, b: any) => _sortBy.sort(a, b))
  }

  const openLightbox = (index: number) => {
    setLightboxSlide(index)
    setShowLightbox(true)
  }
  const changeSortBy = (sortBy: SortbyOption) => {
    setState({
      ...state,
      sortBy,
      galleryItems: getGalleryItemsByCategory({ sortBy }),
    })
  }

  const isVideo = (node: any) => {
    return node?.extension === "mp4" || node?.extension === "MP4"
  }

  const containerOffset = body ? `182px` : `153px`

  const renderTitle = () => (
    <Box
      display="flex"
      justifyContent={{ xs: "center", md: "space-between" }}
      alignItems="center"
      flexDirection={{ xs: "column", md: "row" }}
    >
      <Box fontWeight="fontWeightBold">
        <Typography variant="h3" gutterBottom color="primary">
          {title}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {body}
        </Typography>
      </Box>
      <div>
        <Box
          display="flex"
          alignItems={{ xs: "center", md: "flex-end" }}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box mr={{ md: 3 }}>
            <SortbyMenu
              options={sortOptions}
              selectedOption={state.sortBy}
              onClick={(option: SortbyOption) => {
                changeSortBy(option)
              }}
            />
          </Box>
          <Typography variant="subtitle1" gutterBottom color="primary">
            {`${subtitle || ""} (${state.galleryItems.length})`}
          </Typography>
        </Box>
      </div>
    </Box>
  )

  return (
    <>
      {renderTitle()}
      <div style={{ height: `calc(100vh - ${containerOffset})` }}>
        <AutoSizer>
          {({ height, width }) => {
            return (
              <div>
                <List
                  ref={listRef}
                  width={width - 1}
                  height={height}
                  rowCount={
                    isMobile ? state.galleryItems.length : rowCounts.length
                  }
                  rowHeight={190}
                  style={{ outline: "none" }}
                  noRowsRenderer={() => (
                    <h2 style={{ width: "100%" }}>No items</h2>
                  )}
                  rowRenderer={({ index, key, style }) => {
                    const items = []
                    const row = rowCounts[index]
                    const fromIndex = isMobile
                      ? index
                      : row?.index - row?.itemWidths.length
                    const toIndex = isMobile
                      ? index + 1
                      : fromIndex + row?.itemWidths.length

                    for (let i = fromIndex; i < toIndex; i++) {
                      const node = state.galleryItems[i]
                      const image = isVideo(node)
                        ? videoPlaceholder?.childImageSharp?.fluid
                        : node?.thumbs?.fluid
                      const { aspectRatio } = image
                      const columnWidth = aspectRatio < 1 ? 1 : 2
                      const title = node.name.replace(/\_/g, " ")

                      items.push(
                        <Grid
                          key={`item` + index + i}
                          item
                          xs={12}
                          lg={columnWidth}
                          style={{ height: 190 }}
                        >
                          <GalleryItem
                            onClick={() => openLightbox(i)}
                            image={image}
                            title={title}
                          />
                        </Grid>
                      )
                    }

                    return (
                      <Grid
                        container
                        spacing={1}
                        key={key}
                        style={{ marginBottom: 6, ...style }}
                      >
                        {items}
                      </Grid>
                    )
                  }}
                />
              </div>
            )
          }}
        </AutoSizer>
      </div>

      {showLightbox && (
        <Lightbox
          slide={lightboxSlide}
          items={state.galleryItems}
          onClose={handleCloseLightbox}
        />
      )}
    </>
  )
}
